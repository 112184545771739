import React from "react";

const Section1 = () => {
  return (
    <>
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-6">
            <img src="../image/xyz.png" style={{ width: "100%" }} />
          </div>
          <div className="col-md-6">Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore odit eos, a eaque debitis ab quo delectus dignissimos excepturi. Sequi, illum officia reprehenderit numquam pariatur eveniet, modi esse ipsa odio vitae culpa, cupiditate maiores! Vel, laboriosam numquam? Non odit corrupti dignissimos, possimus sit aliquam, impedit ipsum at, officiis dolore excepturi illum dolor error ab! Cumque atque dolor nihil. Ducimus eum aspernatur nam asperiores suscipit. Cumque alias, dolore in eos odit earum maxime impedit asperiores error, ipsum autem fugiat recusandae sit facilis corrupti quasi suscipit fuga quibusdam voluptas nesciunt quo sapiente nihil. Quis soluta fugit quasi repellat vel optio asperiores sunt?</div>
          
        </div>
      </div>
    </>
  );
};

export default Section1;
