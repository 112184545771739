import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Header from "./Component/Header";
import Footer from "./Component/Footer";
import Home from "./Component/Home";
import "./Style.css";
import ContactUs from "./Component/ContactUs";
import Login from "./Component/Login"  
import AboutUs from "./Component/AboutUs";

const App = () => {
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="contactUs" element={<ContactUs />} />
          <Route path="login" element={<Login />} />
          <Route path="aboutUs" element={<AboutUs />}/>
        </Routes>

        <Footer />
      </Router>
    </>
  );
};

export default App;
