import React from "react";
import { CiTimer } from "react-icons/ci";
import { FaPhoneAlt } from "react-icons/fa";
import { IoHomeOutline } from "react-icons/io5";
import { TbMail } from "react-icons/tb";

const ContactUs = () => {
  return (
    <>
      <div className="container-fluid p-5">
        <div className="row">
          <div className="col-md-6">
            <div className="row">
              <h3>REACH US</h3>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-4">
                    <IoHomeOutline style=
                      {{ fontSize: "35px", color: "rgb(12,123,192)" }} />
                  </div>
                  <div className="col-8">
                    <div className="row">
                      <h3
                        className="contact_headings">ADDRESS
                      </h3>
                      <p style={{ fontsize: '14px' }}>1245 rani sathi,sikar</p>

                    </div>
                  </div>

                  <div className="col-md-6"></div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-4">
                    <FaPhoneAlt style=
                      {{ fontSize: "35px", color: "rgb(12,123,192)" }} />
                  </div>
                  <div className="col-8">
                    <div className="row">
                      <h3
                        className="contact_headings">Phone Number
                      </h3>
                      <p style={{ fontsize: '14px' }}>9521133660</p>
                    </div>
                  </div>

                  <div className="col-md-6"></div>
                </div>
              </div>

            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-4">
                    <CiTimer style=
                      {{ fontSize: "35px", color: "rgb(12,123,192)" }} />
                  </div>
                  <div className="col-8">
                    <div className="row">
                      <h3
                        className="contact_headings">Working Hours
                      </h3>
                      <p style={{ fontsize: '14px' }}>8 Hours</p>

                    </div>
                  </div>

                  <div className="col-md-6"></div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-4">
                    <TbMail style=
                      {{ fontSize: "35px", color: "rgb(12,123,192)" }} />
                  </div>
                  <div className="col-8">
                    <div className="row">
                      <h3
                        className="contact_headings">Email
                      </h3>
                      <p style={{ fontsize: '14px' }}>lokeshrewar@gmail.com</p>
                    </div>
                  </div>

                  <div className="col-md-6"></div>
                </div>
              </div>

            </div>

          </div>
          <div className="col-md-6">
            <form action="" className="contact_form">
              <input type="text" placeholder="Enter First Name" />
              <input type="text" placeholder="Enter Last name" />
              <input type="text" placeholder="Phone number" />
              <input type="email" placeholder="Email" />
              <textarea type="text" placeholder="Message" rows={5} />
              <button type="SUBMIT" className="submit_btn">SUBMIT</button>
            </form>
          </div>
        </div>
      </div>
      <div className="container-fluid p-5">
        <div className="row">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28285.530586366767!2d75.13938997761235!3d27.603098010532776!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399a7dc000000001%3A0x5c5f71003d4717df!2sShekhawati%20Group%20of%20Institutions-Best%20B.Tech%2C%20BCA%2C%20MCA%2C%20BBA%2C%20MBA%20College%20in%20Sikar!5e0!3m2!1sen!2sin!4v1733477321364!5m2!1sen!2sin" width="600" height="450" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        
        </div>
        </div>
    </>
  );
};

export default ContactUs;
