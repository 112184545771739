import React from "react";
import { FaKey } from "react-icons/fa";
import { MdMarkEmailUnread } from "react-icons/md";

const Login = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <img src="https://codingveda.com/images/exams.gif" alt="" height={"400px"}/>
          </div> 
          <div className="col-md-6 login_right_side">
            <h3 className="pb-3">Login</h3>
            <div className="login_input">
              <MdMarkEmailUnread style={{ fontSize: "20px", marginRight: "10px", color:"#fff" }} />
              <input type="email" placeholder="Email" />
            </div>
            <div className="login_input">
              <FaKey style={{ fontSize: "20px", marginRight: "10px", color:"#fff" }} />
              <input type="password" placeholder="Password" />
            </div>
            <div className="login_links">
              <a href="#">SignUp</a>
              <a href="#">Forgot password?</a>
            </div>
            <div className="submit_btn_div">
            <button type="submit" className="submit_btnOne">Login</button>
            </div>
           
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;