import React from "react";
import CountUp from "react-countup";
import { PiStudentBold } from "react-icons/pi";

const Section2 = () => {
  return (
    <>
      <div className="container pt-5">
        <div className="row">
            <div className="col-md-2 course_section">
                <div className="row">
                    <div className="col-sm-4"><PiStudentBold /></div>
                    <div className="col-sm-6">
                        <div className="col">BCA</div>
                        <div className="col"><CountUp end={300} duration={10}/> +</div>
                    </div>
                </div>
            </div>
            <div className="col-md-2 course_section">
                <div className="row">
                    <div className="col-sm-4"><PiStudentBold /></div>
                    <div className="col-sm-6">
                        <div className="col">BCA</div>
                        <div className="col"><CountUp end={300} duration={10}/>+</div>
                    </div>
                </div>
            </div>
            <div className="col-md-2 course_section">
                <div className="row">
                    <div className="col-sm-4"><PiStudentBold /></div>
                    <div className="col-sm-6">
                        <div className="col">BCA</div>
                        <div className="col"><CountUp end={300} duration={10}/>+</div>
                    </div>
                </div>
            </div>
            <div className="col-md-2 course_section">
                <div className="row">
                    <div className="col-sm-4"><PiStudentBold /></div>
                    <div className="col-sm-6">
                        <div className="col">BCA</div>
                        <div className="col"><CountUp end={300} duration={10}/>+</div>
                    </div>
                </div>
            </div>
           
            
            
        </div>
      </div>
    </>
  );
};

export default Section2;
