import React from 'react'

const AboutUs = () => {
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <img src="https://trialfinity.com/images/about_us.jpg" width={600} height={400} alt="" />
                    </div>
                    <div className="col-md-6">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Facilis explicabo, aliquid consequatur itaque dolor id odit error obcaecati molestiae tenetur nihil quia natus libero repellat consectetur suscipit temporibus nobis neque exercitationem debitis? Veniam minus in, excepturi placeat cum, voluptatibus deserunt sunt iusto ut perspiciatis est adipisci voluptate, impedit eos itaque nobis velit aliquid rem dolores debitis iste! Laudantium aliquam ad libero culpa quaerat, molestias aliquid recusandae. Ab ducimus quos minima quo ad laborum? Expedita quibusdam, alias temporibus quaerat facere odio numquam cumque at dolorem sunt, aut incidunt asperiores voluptatibus corrupti fugit eum ipsam totam! Ad, mollitia modi eos sapiente dolores doloribus cumque impedit non qui doloremque rem recusandae inventore. Sapiente consectetur similique ipsum obcaecati, veritatis doloremque temporibus neque nam non adipisci labore. Vero explicabo rerum incidunt architecto, delectus ratione. Temporibus recusandae nesciunt omnis distinctio accusantium explicabo consequatur doloremque, velit enim eius consequuntur sequi rerum vitae sed doloribus unde nobis quisquam ullam tenetur animi, saepe incidunt, debitis quis tempora! Animi, asperiores. Consequatur, tempore. Accusantium, laudantium mollitia dolores consectetur vel praesentium earum voluptas officiis itaque, veniam magnam porro. Itaque, optio sit. A, deleniti asperiores quae voluptate architecto illo libero esse minima nam nulla consequatur beatae aliquid quia repellat voluptas, labore ducimus ipsum.</div>

                </div>
                <div className="container pt-5">
                    <div className="row">
                        <h3 className='about_heading'>Facilites</h3>
                        <p>Trialfinity has a 2,500-square-foot dedicated research facility in Hamilton, New Jersey. Our building is conveniently located near major medical centers and is only 15 miles from the Trenton Airport.</p>
                        <h3 className='about_heading'>Facility Features</h3>
                        <ol type='square'>
                            <li>Fully-alarmed, 24-hour monitored security system with selective pin access</li>
                            <li>Dedicated monitoring rooms with high-speed Internet, copier, phone and fax</li>
                            <li>  24-hour dedicated emergency contact phone</li>
                            <li>Conference room with access to internet</li>
                            <li>Fully-equipped examination rooms</li>

                        </ol>
                        <h3 className='about_heading'>Diagnostic/Clinic Equipment:</h3>

                        <ol type='square'>
                            <li>
                                State of the art CLIA waived laboratory </li>
                            <li>On site emergency equipment including AED, oxygen, crash cart</li>
                            <li>medications (IV fluids, steroids, epinephrine, ASA, inhalers)
                                Ultra So-Lo freezer (-20° and -80°)</li>
                            <li>locked refrigerator and freezer</li>
                            <li>Data log tag 24/7 temperature monitoring system</li>
                            <li>IV/Infusion Capabilities</li>
                            <li>Centrifuge & Refrigerated Centrifuge Incubator</li>


                        </ol>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutUs
